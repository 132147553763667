.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
  --primary-color: #156082;
  --secondary-color: #1b4275;
  --transparancy-color: rgb(251, 191, 44, 0.2);

}

.whiteiconcolor {
    color: white;
}

/* Note Button */
.MenuButton {
  transition: 0.4s;
  position: fixed;
  bottom: 4vh;
  right: 22px;
  z-index: 100;
}

.MenuButton:hover {
  padding-left: 55px;
  padding-top: 55px;
}

.MenuButton:hover .MenuButton__Button {
  transform: rotate(90deg);
}


.MenuButton__Base {
    align-items: center;

    background-color: #166082;
    border-radius: 50%;
    display: flex
;
    justify-content: center;
    transition: .4s;
}

.MenuButton__Base__icon {
    font-family: 'themify';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
}

.MenuButton__Button {
  width: 55px;
  height: 55px;
  font-size: 30px;
  transition: 0.4s;
  z-index: 100;
}

.MenuButton__dropdown {
  position: absolute;
  left: -50px;
  bottom: -500px;
  width: 0px;
  height: 0px;
  font-size: 0px;
  z-index: 0;
  cursor: pointer;
}

.MenuButton:hover .MenuButton__dropdown {
  left: 0px;
  width: 15rem;
  height: auto;
  font-size: 14px;
  /*border-radius: 6px;*/
  background-color: var(--bg-context-menu);
  /*border: 2px solid var(--primary-color);*/
}

.MenuButton:hover .MenuButton__dropdownDiv {
  bottom: 62.5px;
  left: auto !important;
  right: -120px;
}

.MenuButton_buttonDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem;
}

.MenuButton_button-note {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;
}

.MenuButton:hover .MenuButton_button-note {
  /*padding-top: 0.5rem;*/
  /*padding-bottom: 0.5rem;*/
  border: 1px solid var(--primary-color);
  padding-top: 20px;
  margin-bottom: 10px;
  border-radius: 6px;
  width: 120px;
  background-color: white;
}

.MenuButton_button-note p {
  margin-left: 0.5rem;
}

.MenuButton_button-note:hover {
  background-color: var(--border-array);
}
